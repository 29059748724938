import React, { useState, useEffect } from "react";
import "./equipementedit.css";
import {
  Container,
  Box,
  Button,
  InputLabel,
  NativeSelect,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import Navbar from "../../navbar/Navbar";
import Loged from "../../loged/Loged";
import Footer from "../../footer/Footer";
import { useNavigate } from "react-router-dom";
import { db } from "../../../firebase/firebase";
import {
  collection,
  query,
  where,
  onSnapshot,
  doc,
  setDoc,
  deleteDoc,
} from "firebase/firestore";

const EquipementEdit = () => {
  const page = "Modifier un équipement";
  const toolId = window.location.search.split("=")[1];
  const navigate = useNavigate();
  const [toolData, setToolData] = useState<any>();
  const [listUsers, setListUsers] = useState<any>();
  const [open, setOpen] = useState(false);
  const techId = toolData.techobjid;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const listTools = async () => {
    if (!toolData) {
      const q = query(collection(db, "Tools"), where("id", "==", toolId));
      onSnapshot(q, (querySnapshot) => {
        const values: any = [];
        querySnapshot.forEach((doc) => {
          values.push({ ...doc.data(), docId: doc.id });
        });
        setToolData(values[0]);
      });
    }
  };

  const usersList = async () => {
    if (!listUsers) {
      const q = query(collection(db, "Users"), where("status", "!=", "admin"));
      onSnapshot(q, (querySnapshot) => {
        const values: any = [];
        querySnapshot.forEach((doc) => {
          values.push({ ...doc.data() });
        });
        setListUsers(values);
      });
    }
  };

  const handleChange = (e: { target: { id: any; value: any; }; }) => {
    setToolData({ ...toolData, [e.target.id]: e.target.value });
  };

  useEffect(() => {
    usersList();
    listTools();
  });

  async function updateTool() {
    if (toolData.docId) {
      // Add a new document in collection "cities"
      await setDoc(doc(db, "Tools", toolData.docId), {
        ...toolData,
      });
      navigate(`/techniciens?techid=${techId}`);
    }
  }

  async function deleteTool() {
    if (toolData.docId) {
      await deleteDoc(doc(db, "Tools", toolData.docId));
    }
    navigate(`/techniciens?techid=${techId}`);
  }

  return (
    <div id="equipementadd">
      <Container maxWidth="md">
        <Navbar page={page} />
        <Loged />
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Confirmation de suppression de l'équipement"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Vous êtes sur le point de supprimer l'équipement{" "}
              {toolData?.designation} !
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Annuler</Button>
            <Button onClick={deleteTool} autoFocus>
              Oui, Supprimer
            </Button>
          </DialogActions>
        </Dialog>
        <Box mx="auto" style={{ backgroundColor: "white", padding: 10 }}>
          <Box className="content" width="90%" mx="auto">
            <form autoComplete="off">
              <TextField
                id="designation"
                fullWidth
                margin="normal"
                variant="outlined"
                onChange={handleChange}
                placeholder={toolData?.designation}
                helperText="Désignation"
              />
              <TextField
                id="constructid"
                fullWidth
                margin="normal"
                variant="outlined"
                onChange={handleChange}
                placeholder={toolData?.constructid}
                helperText="Id Constructeur"
              />
              <TextField
                id="internalnumber"
                helperText="Numéro Interne"
                fullWidth
                margin="normal"
                variant="outlined"
                onChange={handleChange}
                placeholder={toolData?.internalnumber}
              />
              <TextField
                id="startdate"
                type="date"
                value={toolData?.startdate}
                margin="normal"
                fullWidth
                onChange={handleChange}
                helperText="Mise en service"
              />
              <TextField
                id="controldate"
                type="date"
                value={toolData?.controldate}
                margin="normal"
                fullWidth
                onChange={handleChange}
                helperText="Date de contrôle"
              />
              <Box width="100%" ml={1}>
                <br />
                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                  Périodicité de controle
                </InputLabel>
                <NativeSelect
                  id="periodicity"
                  onChange={handleChange}
                  value={toolData?.periodicity}
                >
                  <option value=""></option>
                  <option value={0}>Non concerné</option>
                  <option value={1}>1 an</option>
                  <option value={2}>2 ans</option>
                  <option value={3}>3 ans</option>
                </NativeSelect>
              </Box>
              <br />
              <TextField
                id="nextcontroldate"
                type="date"
                value={toolData?.nextcontroldate}
                margin="normal"
                fullWidth
                onChange={handleChange}
                helperText="Prochain contrôle"
              />
              <br />
              <Box width="100%" ml={1}>
                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                  Status de l'équipement
                </InputLabel>
                <NativeSelect
                  id="status"
                  onChange={handleChange}
                  value={toolData?.status}
                >
                  <option value="">Non Concerné</option>
                  <option value="Conforme">Conforme</option>
                  <option value="Non Conforme">Non Conforme</option>
                  <option value="Réformé">Réformé</option>
                </NativeSelect>
              </Box>
              <br />
              <Box width="100%" ml={1}>
                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                  Attribution Technicien
                </InputLabel>
                <NativeSelect
                  id="techobjid"
                  onChange={handleChange}
                  value={toolData?.techobjid}
                >
                  <option value=""></option>
                  {listUsers?.map((tech: any, key: any) => {
                    return (
                      <option key={key} value={tech.uid}>
                        {tech.lastname} {tech.firstname}
                      </option>
                    );
                  })}
                </NativeSelect>
              </Box>
              <br />
              <Box mt={2} sx={{ textAlign: "center" }}>
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  sx={{ mx: 1 }}
                  onClick={() => updateTool()}
                >
                  Modifier
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  size="medium"
                  sx={{ mx: 1 }}
                  onClick={handleClickOpen}
                >
                  Supprimer
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
        <Footer />
      </Container>
    </div>
  );
};

export default EquipementEdit;
